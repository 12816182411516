import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GenericService } from './generics/generic.service';
import { map } from 'rxjs/operators';
import { ContractOrderDto } from '../../models/dto/contract-order.dto';

@Injectable({ providedIn: 'root' })
export class ContractOrderService extends GenericService<any> {
	constructor(protected httpClient: HttpClient) {
		super(httpClient);
	}

	getList(): Observable<ContractOrderDto[]> {
		return this.httpClient
			.get<any>(this.api('listarContrato.php'))
			.pipe(map(resp => resp?.contrato?.map((_, i) => new ContractOrderDto(resp.contrato[i], resp.cotacao[i]))));
	}

	geTracker(trackerId: string): Observable<ContractOrderDto> {
		const params = new HttpParams().append('rastreio', trackerId);

		return this.httpClient
			.get<any>(this.api('listarContratoRastreamento.php'), { params })
			.pipe(map(resp => new ContractOrderDto(resp.contrato, resp.cotacao)));
	}
}
