import { ContractMadeDto } from './contract-made/contract-made.dto';
import { QuotationMadeDto } from './contract-made/quotation-made.dto';

export class ContractOrderDto {
	contract: ContractMadeDto;
	quotation: QuotationMadeDto;

	constructor(contract: ContractMadeDto, quotation: QuotationMadeDto) {
		this.contract = contract;
		this.quotation = quotation;
	}
}
